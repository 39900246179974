<template>
   <div class="col-12 px-0 mb-1" :class="toggleCobrancas ? 'main-container' : ''" ref="fatura">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="toggleCobrancas = !toggleCobrancas;">
                  <div class="row align-items-center">
                     <h1 class="font-17 mb-0 limitador-1 text-uppercase">R$ {{ parseFloat(fatura.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</h1>
                     <p class="font-10 mb-1 limitador-1 text-secondary">
                        <span><i class="far fa-hashtag font-9 me-1"></i>{{ fatura.id }}</span>
                        <span class="text-green ms-3" v-if="fatura.pago"><i class="far fa-check font-9 me-1"></i>Pago</span>
                        <span class="text-red ms-3" v-else><i class="far fa-exclamation-triangle font-8 me-1"></i>Pendente</span>
                     </p>
                     <p class="font-12 mb-0 limitador-1">
                        <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Período:</strong>
                        <span>{{ String(fatura.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.inicio.monthValue).padStart(2, '0') +'/'+ fatura.inicio.year }} até</span>
                        <span> {{ String(fatura.fim.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.fim.monthValue).padStart(2, '0') +'/'+ fatura.fim.year }}</span>
                     </p>
                  </div>
               </div>
               <div class="w-max-content font-16 text-center mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <span>{{ String(fatura.vencimento.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.vencimento.monthValue).padStart(2, '0') +'/'+ fatura.vencimento.year }}</span>
                  <small class="d-block font-12"><i class="far fa-calendar color-theme font-10 me-1"></i> Data de vencimento</small>   
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme">
                     <span @click="editarFatura"><i class="far fa-pencil"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="removerFatura"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="toggleCobrancas">
         <div class="col-12 mb-3 d-flex justify-content-between">
            <h1 class="font-15 mt-1 mb-0"><i class="far fa-money-bill-wave-alt font-12 color-theme me-2"></i>Cobranças</h1>
            <div class="w-max-content">
               <a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="editarCobranca(null)"><i class="far fa-plus font-10 me-1"></i> Adicionar</a>
            </div>
         </div>

         <cobranca v-for="(cobranca, index) in fatura.cobrancas" :key="index" :cobranca="cobranca" :index="index" @remover="removerCobranca($event)" @editar="editarCobranca($event)"/>
         <div class="col-12 my-5 text-center" v-if="fatura.cobrancas.length == 0">
            <i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
            <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma cobrança encontrada</h4>
         </div>
      </div>
   </div>
</template>

<script>

import cobranca from '@/components/contas/Cobranca.vue'
import { mapState } from 'vuex'

export default {
   name: 'Fatura',
   props: ['fatura', 'index'],
   data: function () {
      return {
         toggleCobrancas: false
      }
   },
   components: {
      cobranca
   },
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      editarFatura : function () {
         this.$emit('editarFatura', this.fatura)
      },
      removerFatura : function () {
         this.$emit('removerFatura', this.fatura)
      },
      editarCobranca : function (cobranca) {
         this.$emit('editarCobranca', {'fatura': this.fatura, 'cobranca': cobranca})
      },
      removerCobranca : function (cobranca) {
         this.$emit('removerCobranca', cobranca)
      }
   }
}

</script>