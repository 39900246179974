<template>
   <div class="card mb-0" ref="pacote" :index="index">
      <div class="card-img overflow-hidden">
         <img :src="pacote.foto == null ? '' : pacote.foto" class="card-img-top" alt="icon" @error="imageError">
      </div>
      <div class="card-body text-center pt-12">
         <h5 class="limitador-2 color-theme">{{ pacote.nome }}</h5>
         <p class="limitador-1 text-secondary h-auto mb-2" v-if="tela == 'MEUS_PACOTES'">
            <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Desde:</strong>
            <span class="font-12" v-if="pacote.inicio">{{ String(pacote.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(pacote.inicio.monthValue).padStart(2, '0') +'/'+ pacote.inicio.year }}</span>
         </p>
         <p class="align-text-center descricao d-none d-lg-block" v-if="pacote.descricao"><span class="limitador-3">{{ pacote.descricao }}</span></p>
         <p class="limitador-1 valor" v-if="tela == 'PACOTES'">R$ {{ parseFloat(pacote.valor).toFixed(2) }}</p>
         <button class="btn btn-white invert" v-if="tela == 'PACOTES'" @click="contratar"><i class="fal fa-shopping-cart me-1"></i> Contratar</button>
         <button class="btn btn-white invert" v-else @click="cancelar"><i class="fal fa-times me-1"></i> Cancelar</button>
      </div>
   </div>
</template>

<script>

// import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Pacote',
   props: ['pacote', 'index', 'tela'],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest,
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      contratar : function () {
         this.$emit('contratar', this.pacote)
      },
      cancelar : function () {
         this.$emit('cancelar', this.pacote)
      },
   }
}

</script>

<style scoped>

h5 {
   font-size: 14px;
   margin-bottom: 8px;
   font-weight: 500;
   line-height: 20px;
}

.card[index] img {
	aspect-ratio: 1/1;
	object-fit: cover;
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

.descricao {
   height: 60px;
   margin-bottom: 0;
   font-size: 13px;
   line-height: 20px;
}

.valor {
   font-size: 15px;
   font-weight: 500;
   margin-top: 10px;
   margin-bottom: 0;
}

button {
   margin-top: 10px;
   transition: all 0.5s;
   padding: 5px 10px;
   font-size: 12px;
}

button:hover {
   background-color: var(--color-theme);
   border-color: var(--color-theme);
   color: #fff;
}

</style>