<template>
   <div class="row">
      <div class="col-12">
			<div class="card mb-2">
				<div class="card-body p-12">
					<div class="row align-items-center">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> Informações de:</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="getConta()"><i class="far fa-sync-alt font-10 me-1"></i> Recarregar</a>
						</div>

						<div class="col-12 px-0 mb-1" ref="usuario">
							<div class="card mb-0">
								<div class="card-body p-12">
									<div class="row align-items-center">
										<div class="w-max-content pe-12 d-none d-lg-block">
											<img :src="conta.usuario.avatar == null ? '' : conta.usuario.avatar" class="rounded" alt="icon" @error="imageError" height="50" />
										</div>
										<div class="col-md text-center text-md-start">
											<div class="row align-items-center">
												<div class="col-12">
													<h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ conta.usuario.nomeCompleto }}</h1>
													<p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
														<span><i class="far fa-hashtag font-9 me-1"></i>{{ conta.usuario.id }}</span>
														<span class="ms-3">
															<i class="far fa-envelope font-9 me-1"></i>
															<span>{{ conta.usuario.email == null || String(conta.usuario.email).trim().length == 0 ? 'Sem email' : conta.usuario.email }}</span>
														</span>
													</p>
												</div>
												<div class="col d-none d-lg-block">
													<p class="font-12 mb-0 limitador-1">
														<i class="far fa-phone-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Telefone:</strong>
														<span> {{ conta.usuario.telefone == null || conta.usuario.telefone.telefone == null || String(conta.usuario.telefone.telefone).trim().length == 0 ? 'Sem telefone' : (conta.usuario.telefone.ddd +' '+ conta.usuario.telefone.telefone) }}</span>
													</p>
												</div>
											</div>
										</div>
										<div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
											<div class="btn-icone color-theme">
												<span @click="editarUser(conta.usuario)"><i class="far fa-user"></i><small>Usuário</small></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      <div class="col-12">
			<div class="card mb-2">
				<div class="card-body p-12">
					<ul class="nav nav-pills" id="pills-tab" role="tablist">
						<li class="nav-item col" role="presentation">
							<button class="nav-link m-0 active" id="pills-conta-tab" data-bs-toggle="pill" data-bs-target="#pills-conta" type="button" role="tab" aria-controls="pills-conta" aria-selected="true">
								<i class="far fa-sitemap font-12"></i><span class="ms-1 d-none d-sm-inline"> Conta</span>
							</button>
						</li>
						<li class="nav-item col" role="presentation">
							<button class="nav-link m-0" id="pills-usuarios-tab" data-bs-toggle="pill" data-bs-target="#pills-usuarios" type="button" role="tab" aria-controls="pills-usuarios" aria-selected="false">
								<i class="far fa-user font-12"></i><span class="ms-1 d-none d-sm-inline"> Usuários</span>
							</button>
						</li>
						<li class="nav-item col" role="presentation">
							<button class="nav-link m-0" id="pills-financeiro-tab" data-bs-toggle="pill" data-bs-target="#pills-financeiro" type="button" role="tab" aria-controls="pills-financeiro" aria-selected="false">
								<i class="far fa-dollar-sign font-12"></i><span class="ms-1 d-none d-sm-inline"> Financeiro</span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
      <div class="col-12">
			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade show active" id="pills-conta" role="tabpanel" aria-labelledby="pills-conta-tab">
					<div class="card">
						<div class="card-body">
							<div class="cards-3">
								<div class="card cursor-pointer" :class="indicadores.Financeiro == 0 ? '' : indicadores.Financeiro < 3 ? 'border-danger2' : 'border-success'">
									<div class="card-body">
										<div class="info-icon position-absolute top-right" :title="textos.financeiro[indicadores.Financeiro]">
											<i class="fas fa-info"></i>
										</div>
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<div class="d-flex flex-column">
													<i class="far fa-dollar-sign font-25 color-theme mb-2"></i>
													<span class="font-16 weight-500">Financeiro</span>
												</div>
											</div>
											<div>
												<span class="font-20 font-weight-bold" :class="indicadores.Financeiro == 0 ? '' : indicadores.Financeiro < 3 ? 'text-danger' : 'text-success'">{{ 'F' + indicadores.Financeiro }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="card cursor-pointer" :class="indicadores.Tecnico == 0 ? '' : indicadores.Tecnico == 1 ? 'border-danger2' : 'border-success'">
									<div class="card-body">
										<div class="info-icon position-absolute top-right" :title="textos.tecnico[indicadores.Tecnico]">
											<i class="fas fa-info"></i>
										</div>
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<div class="d-flex flex-column">
													<i class="far fa-tools font-25 color-theme mb-2"></i>
													<span class="font-16 weight-500">Técnico</span>
												</div>
											</div>
											<div>
												<span class="font-20 font-weight-bold" :class="indicadores.Tecnico == 0 ? '' : indicadores.Tecnico == 1 ? 'text-danger' : 'text-success'">{{ 'T' + indicadores.Tecnico }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="card cursor-pointer" :class="indicadores.Operacional == 0 ? '' : indicadores.Operacional == 1 ? 'border-danger2' : 'border-success'">
									<div class="card-body">
										<div class="info-icon position-absolute top-right" :title="textos.operacional[indicadores.Operacional]">
											<i class="fas fa-info"></i>
										</div>
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<div class="d-flex flex-column">
													<i class="far fa-tasks font-25 color-theme mb-2"></i>
													<span class="font-16 weight-500">Operacional</span>
												</div>
											</div>
											<div>
												<span class="font-20 font-weight-bold" :class="indicadores.Operacional == 0 ? '' : indicadores.Operacional == 1 ? 'text-danger' : 'text-success'">{{ 'O' + indicadores.Operacional }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="card cursor-pointer" :class="indicadores.Calendario == 0 ? '' : indicadores.Calendario <= 3 ? 'border-danger2' : 'border-success'">
									<div class="card-body">
										<div class="info-icon position-absolute top-right" :title="textos.calendario[indicadores.Calendario]">
											<i class="fas fa-info"></i>
										</div>
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<div class="d-flex flex-column">
													<i class="far fa-calendar font-25 color-theme mb-2"></i>
													<span class="font-16 weight-500">Calendário</span>
												</div>
											</div>
											<div>
												<span class="font-20 font-weight-bold" :class="indicadores.Calendario == 0 ? '' : indicadores.Calendario <= 3 ? 'text-danger' : 'text-success'">{{ 'C' + indicadores.Calendario }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="card cursor-pointer" :class="indicadores.Tecnologia == 0 ? '' : indicadores.Tecnologia < 3 ? 'border-danger2' : 'border-success'">
									<div class="card-body">
										<div class="info-icon position-absolute top-right" :title="textos.tecnologia[indicadores.Tecnologia]">
											<i class="fas fa-info"></i>
										</div>
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<div class="d-flex flex-column">
													<i class="far fa-microchip font-25 color-theme mb-2"></i>
													<span class="font-16 weight-500">Tecnologia</span>
												</div>
											</div>
											<div>
												<span class="font-20 font-weight-bold" :class="indicadores.Tecnologia == 0 ? '' : indicadores.Tecnologia < 3 ? 'text-danger' : 'text-success'">{{ 'Tec' + indicadores.Tecnologia }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane fade" id="pills-usuarios" role="tabpanel" aria-labelledby="pills-usuarios-tab">
					<div class="col-12" v-if="usuarios.length != 0">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row">
									<div class="col mb-2 align-self-center">
										<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ usuarios.length == 100 ? 'Limitado a ': '' }} {{ usuarios.length }} resultado(s)</div>
									</div>
									<div class="w-max-content mb-2">
										<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="editarUser(null)"><i class="far fa-plus font-10 me-1"></i> Adicionar</a>
									</div>

									<usuario v-for="(usuario, index) in usuarios" :key="index" :usuario="usuario" :index="index" @editar="editarUser($event)"/>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-0" v-else>
						<div class="card-body p-12">
							<div class="row">
								<div class="col mb-2 align-self-center">
									<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ usuarios.length == 100 ? 'Limitado a ': '' }} {{ usuarios.length }} resultado(s)</div>
								</div>
								<div class="w-max-content mb-2">
									<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="editarUser(null)"><i class="far fa-plus font-10 me-1"></i> Adicionar</a>
								</div>

								<div class="w-100 my-5 text-center">
									<i class="fal fa-user font-60 text-secondary opacity-50 d-block mb-3"></i>
									<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum usuário encontrado</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane fade" id="pills-financeiro" role="tabpanel" aria-labelledby="pills-financeiro-tab">
					<div class="row align-items-start">
						<div class="nav flex-sm-column nav-pills mx-auto px-sm-0 mb-1 mb-sm-0 justify-content-center" id="config-tab" role="tablist" aria-orientation="vertical">
							<button class="nav-link mb-1 active" id="faturas-tab" data-bs-toggle="pill" data-bs-target="#faturas" type="button" role="tab" aria-controls="faturas" aria-selected="true">
								<span class="d-flex align-items-center">
									<i class="fal fa-barcode mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Faturas</span>
								</span>
							</button>
							<button class="nav-link mb-1" id="meus-pacotes-tab" data-bs-toggle="pill" data-bs-target="#meus-pacotes" type="button" role="tab" aria-controls="meus-pacotes" aria-selected="false">
								<span class="d-flex align-items-center">
									<i class="fal fa-browser mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Meus pacotes</span>
								</span>
							</button>
							<button class="nav-link mb-1" id="pacotes-tab" data-bs-toggle="pill" data-bs-target="#pacotes" type="button" role="tab" aria-controls="pacotes" aria-selected="false">
								<span class="d-flex align-items-center">
									<i class="fal fa-shopping-cart mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Pacotes</span>
								</span>
							</button>
							<!-- <button class="nav-link mb-1" id="contatos-tab" data-bs-toggle="pill" data-bs-target="#contatos" type="button" role="tab" aria-controls="contatos" aria-selected="false">
								<span class="d-flex align-items-center">
									<i class="fal fa-address-book mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Contatos</span>
								</span>
							</button> -->
						</div>

						<div class="tab-content px-sm-0" id="config-tabContent">
							<div class="tab-pane fade show active" id="faturas" role="tabpanel" aria-labelledby="faturas-tab">
								<div class="row">
									<div class="col-12 mb-1">
										<div class="card mb-0">
											<div class="card-body p-12">
												<div class="d-flex justify-content-between">
													<h6 class="my-1 font-15 text-capitalize align-self-center"><i class="fas fa-barcode color-theme me-2"></i>Faturas</h6>
													<div class="w-max-content">
														<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="editarFatura(null)"><i class="far fa-plus font-10 me-1"></i> Adicionar</a>
													</div>
												</div>
												<div class="w-100 my-5 text-center" v-if="faturas.length == 0">
													<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
													<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma fatura encontrada</h4>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12">
										<div class="row">
											<fatura v-for="(fatura, index) in faturas" :key="index" :fatura="fatura" :index="index" @editarFatura="editarFatura($event)" @removerFatura="removerFatura($event)" @removerCobranca="removerCobranca($event)" @editarCobranca="editarCobranca($event)"/>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="meus-pacotes" role="tabpanel" aria-labelledby="meus-pacotes-tab">
								<div class="row">
									<div class="col-12 mb-1">
										<div class="card mb-0">
											<div class="card-body p-12">
												<h6 class="my-1 font-15 text-capitalize"><i class="far fa-browser color-theme me-2"></i>Meus pacotes</h6>
												<div class="w-100 my-5 text-center" v-if="meusPacotes.length == 0">
													<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
													<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum pacote encontrado</h4>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12">
										<div class="cards-150 cards-sm-175 cards-lg-225 cards-xxl-250">
											<pacote v-for="(pacote, index) in meusPacotes" :key="index" :pacote="pacote" :index="index" :tela="'MEUS_PACOTES'" @cancelar="cancelarPacote($event)" />
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pacotes" role="tabpanel" aria-labelledby="pacotes-tab">
								<div class="row">
									<div class="col-12 mb-1">
										<div class="card mb-0">
											<div class="card-body p-12">
												<h6 class="my-1 font-15 text-capitalize"><i class="far fa-shopping-cart color-theme me-2"></i>Pacotes</h6>
												<div class="w-100 my-5 text-center" v-if="pacotes.length == 0">
													<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
													<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum pacote encontrado</h4>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12">
										<div class="cards-150 cards-sm-175 cards-lg-225 cards-xxl-250">
											<pacote v-for="(pacote, index) in pacotes" :key="index" :pacote="pacote" :index="index" :tela="'PACOTES'" @contratar="contratarPacote($event)"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- modalListaUsuario -->
		<div class="modal fade" id="modalVerUsuarioLista" tabindex="-1" aria-labelledby="modalVerUsuarioListaLabel" aria-hidden="true">
			<div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content h-100">
					<div class="modal-header">
						<h5 class="modal-title" id="modalVerUsuarioListaLabel">Editar usuário master</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">					
                  <div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-usuario-tab" data-bs-toggle="pill" data-bs-target="#pills-usuario" type="button" role="tab" aria-controls="pills-usuario" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-permissoes-tab" data-bs-toggle="pill" data-bs-target="#pills-permissoes" type="button" role="tab" aria-controls="pills-permissoes" aria-selected="false">
											<i class="far fa-lock font-12"></i><span class="ms-1 d-none d-sm-inline"> Permissões</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-usuario" role="tabpanel" aria-labelledby="pills-usuario-tab">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												<i class="fal fa-home"></i> Informações básicas
											</button>
										</h2>
										<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
														<input type="text" class="form-control" v-model="modalUsuario.nomeCompleto" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
														<input type="text" class="form-control" v-model="modalUsuario.email" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF *</label>
														<input type="text" class="form-control" v-model="modalUsuario.cpf" v-mask="'###.###.###-##'" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> RG</label>
														<input type="text" class="form-control" v-model="modalUsuario.rg" maxlength="25" />
													</div>
													<div class="col-sm-6 mb-3" v-if="conta.usuario.id == modalUsuario.id">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo empresa *</label>
														<v-select name="Desktop" :options="['Lucro real', 'Simples nacional']" v-model="modalUsuario.tipoEmpresa" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modalUsuario.tipoEmpresa">
															<option v-for="(option, index) in ['Lucro real', 'Simples nacional']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-sm-6">
														<label class="mb-1"><i class="far fa-lock color-theme me-1 font-11"></i>Senha</label>
														<input type="password" class="form-control font-14" v-model="modalUsuario.senha" maxlength="200">
													</div>
												</div>
											</div>
										</div>
									</div>
                           <div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<i class="fal fa-map-marker-alt me-3"></i>Endereço
											</button>
										</h2>
										<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modalUsuario.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
															<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
																<i class="fas fa-search"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.rua" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.numero" maxlength="200" />
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.bairro" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.cidade" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.estado" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.codMunicipio" maxlength="200" />
													</div>
													<div class="col-sm-8">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
														<input type="text" class="form-control" v-model="modalUsuario.endereco.complemento" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												<i class="fal fa-phone-alt"></i> Telefone
											</button>
										</h2>
										<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-3 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
														<input type="text" class="form-control" v-model="modalUsuario.telefone.ddd" v-mask="['(##)', '(###)']" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modalUsuario.telefone.telefone" v-mask="['####-####', '#####-####']" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
														<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modalUsuario.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modalUsuario.telefone.tipo">
															<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-12">
														<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
														<input type="text" class="form-control" v-model="modalUsuario.telefone.nomeContato" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-permissoes" role="tabpanel" aria-labelledby="pills-permissoes-tab">
								<div class="row">
									<div class="col-lg-6 ps-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="col-12 mb-3">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-browser color-theme font-13 me-2"></i>Módulos</h6>
													</div>
													<modulo v-for="(modulo, index) in modalUsuario.modulos" :key="index" :index="index" :modulo="modulo" @toggle="toggleModulo($event)" />
													<div class="col-12 my-5 text-center" v-if="modalUsuario.modulos.length == 0">
														<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum módulo encontrado</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-6 pe-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="col-12 mb-3">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-store color-theme font-13 me-2"></i>Lojas</h6>
													</div>
													<checkLoja v-for="(loja, index) in modalUsuario.lojas" :key="index" :index="index" :loja="loja" />
													<div class="col-12 my-5 text-center" v-if="modalUsuario.lojas.length == 0">
														<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum closer encontrado</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarContaUsuario">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalVerFatura -->
		<div class="modal fade" id="modalVerFatura" tabindex="-1" aria-labelledby="modalVerFaturaLabel" aria-hidden="true">
			<div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalVerFaturaLabel">{{ modalFatura.id == null ? 'Adicionar fatura' : 'Editar fatura' }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">					
                  <div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-6 mb-3">
										<label><i class="far fa-money-bill-wave-alt color-theme font-11 me-1"></i> Valor</label>
										<input type="text" class="form-control" v-model="modalFatura.total" maxlength="20">
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Vencimento</label>
										<input type="text" class="form-control" v-model="modalFatura.vencimento" v-mask="'##/##/####'" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Início</label>
										<input type="text" class="form-control" v-model="modalFatura.inicio" v-mask="'##/##/####'" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Fim</label>
										<input type="text" class="form-control" v-model="modalFatura.fim" v-mask="'##/##/####'" />
									</div>
									<div class="col-12 px-0 mb-3 align-self-end">
										<div class="row">
											<div class="col">
												<label><i class="far fa-calendar color-theme font-11 me-1"></i> Status</label>
												<button type="button" class="btn w-100" :class="modalFatura.pago ? 'btn-success' : 'btn-danger'" @click="modalFatura.pago = !modalFatura.pago"><i class="fas font-13 me-1" :class="modalFatura.pago ? 'fa-check' : 'fa-clock'"></i> {{ modalFatura.pago ? 'Pago' : 'Pendente' }}</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarFatura">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalVerCobrança -->
		<div class="modal fade" id="modalVerCobranca" tabindex="-1" aria-labelledby="modalVerCobrancaLabel" aria-hidden="true">
			<div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content h-50">
					<div class="modal-header">
						<h5 class="modal-title" id="modalVerCobrancaLabel">{{ modalFatura.id == null ? 'Adicionar cobrança' : 'Editar cobrança' }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">					
                  <div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-6 mb-3">
										<label><i class="far fa-desktop color-theme font-11 me-1"></i> Serviço</label>
										<v-select name="Desktop" :options="servico" v-model="modalCobranca.idServico" :reduce="p => p.id" label="nome" :clearable="false" placeholder="Selecione um serviço">
											<template v-slot:no-options>Sem resultados</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modalCobranca.idServico">
											<option v-for="(option, index) in servico" :key="index" :value="option.id">{{ option.nome }}</option>
										</select>
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-money-bill-wave-alt color-theme font-11 me-1"></i> Valor</label>
										<input type="text" class="form-control" v-model="modalCobranca.valor" maxlength="20">
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Início</label>
										<input type="text" class="form-control" v-model="modalCobranca.inicio" v-mask="'##/##/####'" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Fim</label>
										<input type="text" class="form-control" v-model="modalCobranca.fim" v-mask="'##/##/####'" />
									</div>
									<div class="col-12 px-0 mb-3 align-self-end">
										<div class="row">
											<div class="col">
												<label><i class="far fa-calendar color-theme font-11 me-1"></i> Proporcional</label>
												<button type="button" class="btn w-100" :class="modalCobranca.proporcional ? 'btn-success' : 'btn-danger'" @click="modalCobranca.proporcional = !modalCobranca.proporcional"><i class="fas font-13 me-1" :class="modalCobranca.proporcional ? 'fa-check' : 'fa-times'"></i> {{ modalCobranca.proporcional ? 'Ativado' : 'Desativado' }}</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarCobranca">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import fatura from '@/components/contas/Fatura.vue'
import modulo from '@/components/contas/CheckModulo.vue'
import checkLoja from '@/components/contas/CheckLoja.vue'
import usuario from '@/components/contas/Usuario.vue'
import pacote from '@/components/info/Pacote.vue'

export default {
	name: 'Info',
   data: function () {
		return {
			faturas: [],
			servico: [],
			usuarios: [],
			meusPacotes: [],
			indicadores: [],
			pacotes: [],
			conta: {'id': null, 'qtdPdvs': null, 'qtdLojas': null, 'qtdUsuarios': null, 'desabilidado': false,
            'closer':{'id': null, 'cpfCnpj': null, 'inscricaoEstadual': null, 'nomeFantasia': null, 'razaoSocial': null, 'pessoaFisica': false, 
               'email': null, 'dataCriacao': null, 'gerencialEnderecos': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 
               'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''}, 'gerencialTelefones': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'},
               'desabilitado': false, 'habilitado': false
            },
            'usuario': {'id': null, 'ultimaSolicitacao': null, 'numeroLojaConvenio': null, 'nomeLojaConvenio': null, 'rg': null, 'cpf': null, 'idConta': null, 'email': null, 
               'senha': null, 'nomeCompleto': null, 'dataNascimento': {}, 'codigoSupervisor': null, 'codigoConvenio': null,
               'avatar':null, 'valor': null, 'valorUtilizado': null, 'lojaConvenio': null, 'desabilitado': false, 'convenioHabilitado': false, 'tentativasLogin': false,
               'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
               'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'modulos': [], 'lojas': [], 'roles': []
            }
         },
			modalUsuario: {'id': null, 'tipoEmpresa': null, 'ultimaSolicitacao': null, 'numeroLojaConvenio': null, 'nomeLojaConvenio': null, 'rg': null, 'cpf': null, 'idConta': null, 'email': null, 
				'senha': null, 'nomeCompleto': null, 'dataNascimento': {}, 'codigoSupervisor': null, 'codigoConvenio': null,
				'avatar':null, 'valor': null, 'valorUtilizado': null, 'lojaConvenio': null, 'desabilitado': false, 'convenioHabilitado': false, 'tentativasLogin': false,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'modulos': [], 'lojas': [], 'roles': [], 'isMasterUsuario': false
			},
			modalFatura: {
				'id': null, 'inicio': null, 'fim': null, 'vencimento': null, 'data': [new Date(), new Date()], 
				'total': null, 'pago': false, 'valor': null, 'idConta': null,
			},
			modalCobranca: {
				'id': null, 'idServico': null, 'inicio': null, 'fim': null, 
				'valor': null, 'proporcional': false, 'servico': null
			},
			textos: {
				financeiro: [
					'Sem avaliação',
					'Inadimplente a -MM',
					'Atrasado',
					'Não possue débitos',
					'Não possue débitos a -MM',
					'Não possue débitos a -A'
				],
				tecnico: [
					'Sem avaliação',
					'Não vai conseguir',
					'Vai conseguir com dificuldade',
					'Vai conseguir usar normal',
					'Vai usar com facilidade',
					'Vai usar e ser modelo para outras empresas'
				],
				operacional: [
					'Sem avaliação',
					'Inexistente',
					'Usa bem pouco',
					'Normal',
					'Usa todos os dias',
					'Empresa totalmente dependente'
				],
				calendario: [
					'Sem avaliação',
					'Não consegue contato',
					'Consegue contato, mas não consegue marcar',
					'Marcou e não compareceu',
					'Marcou e compareceu',
					'Facilidade em marcar e realizar reunião/atendimento'
				],
				tecnologia: [
					'Sem avaliação',
					'Inexistente',
					'Mínimo necessário, tem de melhorar',
					'Vai rodar',
					'Rodar com bom desempenho',
					'Rodar sobrando desempenho'
				]
			}
			
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
	},
   components: {
		usuario, modulo, checkLoja, fatura, pacote
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		toggleModulo : function (idModulo) {
         this.modalUsuario.modulos.forEach(modulo => {
            if (modulo.id == idModulo) {
               modulo.habilitado = !modulo.habilitado

               modulo.roles.forEach(role => {
                  role.habilitado = modulo.habilitado
               });
            }
         });
      },
      getConta : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getConta',
				params: {
					id: this.$route.query.idConta
				}
			}).then(response => {
				this.faturas = JSON.parse(JSON.stringify(response.data.faturas)),
				this.conta = {
               'id': response.data.conta.id,
					'usuario': JSON.parse(JSON.stringify(response.data.conta.usuario)),
					'closer': JSON.parse(JSON.stringify(response.data.conta.closer)),
					'qtdPdvs': response.data.conta.qtdPdvs,
					'qtdLojas': response.data.conta.qtdLojas, 
					'qtdUsuarios': response.data.conta.qtdUsuarios, 
					'desabilitado': response.data.conta.desabilitado,
				}
				this.usuarios = JSON.parse(JSON.stringify(response.data.usuarios))
				this.meusPacotes = JSON.parse(JSON.stringify(response.data.meusPacotes))
				this.pacotes = JSON.parse(JSON.stringify(response.data.pacotes))
				this.indicadores = JSON.parse(JSON.stringify(response.data.indicadores))
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      buscarCEP : function () {
			if (String(this.modalUsuario.endereco.cep).trim().length < 8) {
				return;
			}

			this.$store.dispatch('buscarCEP', this.modalUsuario.endereco.cep).then((data) => {
				this.modalUsuario.endereco.rua = data.logradouro
				this.modalUsuario.endereco.complemento = data.complemento
				this.modalUsuario.endereco.bairro = data.bairro
				this.modalUsuario.endereco.cidade = data.localidade
				this.modalUsuario.endereco.estado = data.uf
				this.modalUsuario.endereco.codMunicipio = data.ibge
			})
      },
		editarUser : function(user) {
			this.modalUsuario = {'id': null, 'isMasterUsuario': false, 'tipoEmpresa': null, 'ultimaSolicitacao': null, 'numeroLojaConvenio': null, 'nomeLojaConvenio': null, 'rg': null, 'cpf': null, 'idConta': null, 'email': null, 
				'senha': null, 'nomeCompleto': null, 'dataNascimento': {}, 'codigoSupervisor': null, 'codigoConvenio': null,
				'avatar':null, 'valor': null, 'valorUtilizado': null, 'lojaConvenio': null, 'desabilitado': false, 'convenioHabilitado': false, 'tentativasLogin': false,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'modulos': [], 'lojas': [], 'roles': []
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getUsuarioById',
				params: {
					id: user == null ? null : user.id
				}
			}).then(response => {
				this.modalUsuario = {
					'id': response.data.id, 'ultimaSolicitacao': response.data.ultimaSolicitacao,
					'numeroLojaConvenio': response.data.numeroLojaConvenio, 'nomeLojaConvenio': response.data.nomeLojaConvenio,
					'rg': response.data.rg, 'cpf': response.data.cpf, 'idConta': response.data.idConta, 'email': response.data.email,
					'senha': response.data.senha, 'nomeCompleto': response.data.nomeCompleto, 'dataNascimento': response.data.dataNascimento,
					'codigoSupervisor': response.data.codigoSupervisor, 'codigoConvenio': response.data.codigoConvenio,
					'avatar': response.data.avatar, 'valor': response.data.valor, 'valorUtilizado': response.data.valorUtilizado,
					'lojaConvenio': response.data.lojaConvenio, 'desabilitado': response.data.desabilitado, 'convenioHabilitado': response.data.convenioHabilitado,
					'tentativasLogin': response.data.tentativasLogin,
					'tipoEmpresa': response.data.tipoEmpresa,
					'endereco': response.data.endereco, 'telefone': response.data.telefone,
					'modulos': JSON.parse(JSON.stringify(response.data.modulos)),
					'lojas': JSON.parse(JSON.stringify(response.data.lojas)),
					'roles': JSON.parse(JSON.stringify(response.data.roles))
				}

				if (this.modalUsuario.idConta == null) {
					this.modalUsuario.idConta = this.conta.id
				}

				if (user != null) {
					user.id == this.conta.usuario.id ? this.modalUsuario.isMasterUsuario = true : this.modalUsuario.isMasterUsuario = false
				} else {
					this.modalUsuario.isMasterUsuario = false
				}

				$('#modalVerUsuarioLista').modal('show')
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarContaUsuario : function () {
			if (this.modalUsuario.cpf == null || String(this.modalUsuario.cpf).trim().length == 0 ||
				this.modalUsuario.nomeCompleto == null || String(this.modalUsuario.nomeCompleto).trim().length == 0 ||
				this.modalUsuario.tipoEmpresa == null || String(this.modalUsuario.tipoEmpresa).trim().length == 0 ||
				this.modalUsuario.email == null || String(this.modalUsuario.email).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveContaUsuario',
				headers: {'Content-Type': 'application/json'},
				data: this.modalUsuario

			}).then(() => {
				$('#modalVerUsuarioLista').modal('hide')
				this.getConta()

				this.$toast.fire({
					icon: 'success',
					title: 'Salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		contratarPacote : function (pacote) {
			Swal.fire({
            icon: 'warning',
            title: 'Contratar pacote?',
            text: 'Será cobrado o valor proporcional ao tempo de uso na próxima fatura.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoesAdmin/buyPacote',
                  params: {
                     id: pacote.id,
							idConta: this.conta.id
                  }
               }).then(() => {
                  this.getConta()

						this.$toast.fire({
                     icon: 'success',
                     title: 'Pacote contratado!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
		},
		cancelarPacote : function (pacote) {
         Swal.fire({
            icon: 'warning',
            title: 'Cancelar pacote?',
            text: 'Será cobrado o valor proporcional ao tempo de uso na próxima fatura.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoesAdmin/cancelPacote',
                  params: {
                     id: pacote.id,
                     idConta: this.conta.id
                  }
               }).then(() => {
                  this.getConta()

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Pacote cancelado!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
		editarFatura : function(fatura) {
			this.modalFatura = {
				'id': null, 'inicio': null, 'fim': null, 'vencimento': null, 'data': [], 
				'total': null, 'pago': false , 'idConta': null
			},

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getFatura',
				params: {
					id: fatura == null ? null : fatura.id
				}

			}).then(response => {
				this.modalFatura = {
					'id': response.data.id, 'total': response.data.total,'data': response.data.data,
					'idConta': this.$route.query.idConta,
					'pago': response.data.pago,
					'inicio': response.data.inicio == null ? null : String(response.data.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.inicio.monthValue).padStart(2, '0') +'/'+ response.data.inicio.year,
					'fim': response.data.fim == null ? null : String(response.data.fim.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.fim.monthValue).padStart(2, '0') +'/'+ response.data.fim.year,
					'vencimento': response.data.vencimento == null ? null : String(response.data.vencimento.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.vencimento.monthValue).padStart(2, '0') +'/'+ response.data.vencimento.year,
				},

				$('#modalVerFatura').modal('show')
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarFatura : function () {
			if (this.modalFatura.total == null || String(this.modalFatura.total).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha o valor da fatura'
				});

				return
			}

			if (this.modalFatura.vencimento == null || String(this.modalFatura.vencimento).trim().length < 8) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha a data do vencimento da fatura'
				});

				return
			}

			if (this.modalFatura.inicio == null || String(this.modalFatura.inicio).trim().length < 8) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha a data inicial da fatura'
				});

				return
			}

			if (this.modalFatura.fim == null || String(this.modalFatura.fim).trim().length < 8) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha a data final da fatura'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveFatura',
				headers: {'Content-Type': 'application/json'},
				data: this.modalFatura

			}).then(() => {
				$('#modalVerFatura').modal('hide')
				this.getConta()

				this.$toast.fire({
					icon: 'success',
					title: 'Salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		removerFatura : function (fatura) {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir fatura?',
            text: 'O usuário perderá acesso ao sistema.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoesAdmin/deleteFatura',
                  params: {
                     id: fatura.id
                  }
               }).then(() => {
                  this.getConta()

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Fatura excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
		editarCobranca : function(dados) {
			this.modalCobranca = {
				'id': null, 'idServico': null, 'idConta': null, 'inicio': null, 'fim': null, 
				'valor': null, 'proporcional': false, 'servico': []
			},

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getCobranca',
				params: {
					id: dados.cobranca == null ? null : dados.cobranca.idCobranca,
					idConta: this.$route.query.idConta
				}

			}).then(response => {
				this.modalCobranca = {
					'id': response.data.cobranca.id, 'idServico': response.data.cobranca.idServico,
					'idFatura': dados.fatura.id,
					'valor': response.data.cobranca.valor,
					'inicio': response.data.cobranca.inicio == null ? null : String(response.data.cobranca.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.cobranca.inicio.monthValue).padStart(2, '0') +'/'+ response.data.cobranca.inicio.year,
					'fim': response.data.cobranca.fim == null ? null : String(response.data.cobranca.fim.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.cobranca.fim.monthValue).padStart(2, '0') +'/'+ response.data.cobranca.fim.year,
					'proporcional': response.data.cobranca.proporcional
				},
				this.servico = response.data.servico

				$('#modalVerCobranca').modal('show')
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarCobranca : function () {
			if (this.modalCobranca.valor == null || String(this.modalCobranca.valor).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha o valor da cobrança'
				});

				return
			}

			if (this.modalCobranca.inicio == null || String(this.modalCobranca.inicio).trim().length < 8) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha a data inicial da cobrança'
				});

				return
			}

			if (this.modalCobranca.fim == null || String(this.modalCobranca.fim).trim().length < 8) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha a data final da cobrança'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveCobranca',
				headers: {'Content-Type': 'application/json'},
				data: this.modalCobranca

			}).then(() => {
				$('#modalVerCobranca').modal('hide')
				this.getConta()

				this.$toast.fire({
					icon: 'success',
					title: 'Salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		removerCobranca : function (cobranca) {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir cobranca?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoesAdmin/deleteCobranca',
                  params: {
                     id: cobranca.idCobranca
                  }
               }).then(() => {
                  this.getConta()

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Cobrança excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
   },
   mounted() {
      Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
      this.getConta()
   }
}

</script>

<style scoped>
	.cards-3 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		gap: 0.5rem;
		padding: 0;
		list-style-type: none;
	}
	.font-25 {
		font-size: 25px;
	}
	.card-body.position-relative {
		position: relative;
	}
	.info-icon {
		position: absolute;
		top: 5px;
		right: 8px;
		font-size: 13px;
		padding-left: 10px;
		padding-right: 5px;
		color: rgb(128, 128, 128);
	}
	.info-icon i {
		cursor: pointer;
	}

	@keyframes blink-border {
		0% {
			border-color: #dc3545;
		}
		30% {
			border-color: #dc3545;
		}
		50% {
			border-color: transparent;
		}
		70% {
			border-color: #dc3545;
		}
		100% {
			border-color: #dc3545;
		}
	}

	.border-danger2 {
		border: 1px solid #dc3545;
		animation: blink-border 1s infinite;
	}

	#config-tab .nav-link i {
		font-size: 18px;
		width: 21px;
		text-align: center;
	}

	#config-tab .nav-link i.fa-address-book {
		transform: translateX(4px);
	}

	#config-tab .nav-link {
		line-height: 1;
		padding: 14px 20px;
		text-align: start;
		font-weight: 500;
		text-transform: uppercase;
		font-size: 12px;
		width: auto;
		flex: 1 0 0%;
		transition: none !important;
	}

	#config-tab .nav-link * {
		transition: none !important;
	}

	#config-tab,
	#config-tabContent {
		width: 100%;
	}

	@media (min-width: 576px) {
		#config-tabContent {
			min-height: 210px;
			width: calc(100% - 85px)
		}
		
		#config-tab {
			width: 85px;
		}
	}

	@media (min-width: 1200px) {
		#config-tabContent {
			min-height: 210px;
			width: calc(100% - 210px)
		}

		#config-tab {
			width: 210px;
		}
	}

	@media (max-width: 1000px) {
		.cards-3 {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 0.5rem;
			padding: 0;
			list-style-type: none;
		}
	}
	@media (max-width: 575px) {
		.cards-3 {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0.5rem;
			padding: 0;
			list-style-type: none;
		}
	}
</style>