<template>
   <div class="col-12 col-xxl-6 px-1 ps-xxl-0">
      <div class="card mb-1" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-md-block">
                  <img :src="cobranca.foto == null ? '' : cobranca.foto" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm">
                  <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ cobranca.nomeCobranca == null ? 'Sem nome' : cobranca.nomeCobranca }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ cobranca.idCobranca }}</span>
                  </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Período:</strong>
                     <span>{{ String(cobranca.inicioCobranca.dayOfMonth).padStart(2, '0') +'/'+ String(cobranca.inicioCobranca.monthValue).padStart(2, '0') +'/'+ cobranca.inicioCobranca.year }} até</span>
                     <span> {{ String(cobranca.fimCobranca.dayOfMonth).padStart(2, '0') +'/'+ String(cobranca.fimCobranca.monthValue).padStart(2, '0') +'/'+ cobranca.fimCobranca.year }}</span>
                  </p>
               </div>
               <div class="w-max-content font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0"><small class="color-theme">R$ </small>{{ parseFloat(cobranca.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pencil"></i></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

// import Swal from 'sweetalert2'

export default {
   name: 'Cobranca',
   props: ['cobranca', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         this.$emit('editar', this.cobranca)
      },
      remover : function () {
         this.$emit('remover', this.cobranca)
      },
   }
}

</script>

<style scoped>
.btn-icone i {
  font-size: 11px !important;
  margin-right: 0px;
  transform: translateY(-1px);
}
</style>